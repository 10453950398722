.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  background-color: white;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
}

.modal-close {
  position: absolute;
  bottom: 5px;
  right: 5px;
  border: none;
  background: none;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
}

.divider{
  background-color: rgba(152, 152, 152, 0.34);
    height: 1px;
    margin: 1rem 0;
  text-align: center;

}
.card{
    border-radius: 1rem;
    padding: 1rem;
    margin: 5px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgba(155, 155, 155, 0.43);

}
.card-basic{
  display: flex;
  flex-direction: row;
    justify-content: space-between;
  align-content: center;
  padding-top: 1rem;
  align-items: center;
    border-radius: 1rem;

  margin: 1px;
  border: 1px solid rgba(155, 155, 155, 0.43);
}

.text-center{
  text-align: center;
}