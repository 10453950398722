* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "sans-serif", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  }
  
  input {
    margin-left: 5px;
    margin-right: 5px;
  }
  button {
    margin-left: 10px;
    margin-right: 5px;
    display: inline-block;
    border: 2px solid black;
    border-radius: 4px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    color: #333;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  
  .loginBox {
    background: rgba(236, 236, 236, 0.55);
    margin: 1rem;
    border-radius: 1rem;
  }
  
  .bg-light {
    background: rgba(236, 236, 236, 0.19);
    padding: 1rem;
  }
  
  .links {
    margin: 1rem;
    background: #e9e9e952;
    border-radius: 1rem;
    padding: 1rem;
  }
  
  .links a {
    color: #000000;
    text-decoration: none;
  }
  
  /*.column {*/
  /*    text-align: center;*/
  /*}*/
  
  p {
    line-height: 1;
  }
  
  .navbar {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    background: #f8be15;
  }
  
  .navbar-item {
    padding: 0.5rem 1rem;
    font-weight: bold;
    color: rgb(255, 255, 255);
    background-color: #04000a;
    margin: 10px;
    border: none;
  }


  
  
  .button-nav {
    background: none;
    border-radius: 1rem;
    padding: 1rem;
    cursor: pointer;
    border: none;
    color: black;
  }
  .button-nav:hover {
    background: none;
    padding: 1rem;
    cursor: pointer;
    border: none;
    color: black;
    text-decoration: underline;
  }
  
  .divider {
    background-color: rgba(152, 152, 152, 0.34);
    height: 1px;
    margin: 1rem 0;
    text-align: center;
  }
  .card {
    border-radius: 0.5rem;
    padding: 2rem;
    margin: 5px;
    background-color: white;
    /*border: 1px solid rgba(155, 155, 155, 0.43);*/
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }
  .card-vertical {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 1rem;
    margin: 1rem;
    border-radius: 1rem;
    background-color: white !important;
    border: 1px solid rgba(155, 155, 155, 0.43);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  }
  .card:hover {
    /* border: 1px solid rgba(155, 155, 155, 0.43); */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }
  
  .card-basic {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding-top: 1rem;
    align-items: center;
    border-radius: 1rem;
  
    margin: 1px;
    border: 1px solid rgba(155, 155, 155, 0.43);
  }
  
  .text-center {
    text-align: center;
  }
  /* Default button style */
  .button-clear,
  .button-outline {
    display: inline-block;
    border: 2px solid black;
    border-radius: 4px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    color: #333;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  
  /* Hover state */
  .button:hover {
    background-color: black !important;
    color: #ffffff !important;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2) !important;
  }
  
  /* Active state */
  .button:active {
    transform: translateY(1px);
    background-color: #333;
    color: #fff;
    border-color: #333;
  }
  
  /* Disabled state */
  .button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .no-underlined {
    text-decoration: none;
    color: black;
  }
  .remove-formatting {
    text-decoration: none !important;
    color: black !important;
    background-color: transparent !important;
    border: none !important;
  }
  /*.notificationMessage{*/
  /*    background-color: #ffffff;*/
  /*    color: black;*/
  /*    padding: 1rem;*/
  /*    border-radius: 1rem;*/
  /*    margin: 1rem;*/
  /*    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);*/
  /*    display: flex;*/
  /*    justify-content: space-between;*/
  /*    align-content: center;*/
  /*    align-items: center;*/
  /*    text-align: center;*/
  /*}*/
  
  .notificationMessage {
    position: fixed;
    bottom: 50px;
    right: 50px;
    padding: 12px 16px;
    background-color: #ffffff;
    color: #04000a;
    border-radius: 4px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.15);
    z-index: 10;
    animation: hideNotification 2s ease-in-out forwards;
    font-size: 2rem;
    font-weight: 700;
  }
  
  @keyframes hideNotification {
    0% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
  

  .paper{
    background-color: #ffffff;
    flex-direction: column;
    color: black;
    padding: 1rem;
    border-radius: 1rem;
    margin: 1rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    text-align: center;
  }